////////////////// NEWSLETTER SECTION //////////////////
.newsletterSection-wrapper {
  @include margin(top, $gap-xl);
  @include margin(bottom, $gap-xl);
  @include grid(small);
  @include align(center);
}

.newsletterSection-title {
  @include h2;
  @include green;
  @include margin(bottom, $gap-sm);

  & + div {
    @include margin(bottom);
    color: darken($white, 40%);
  }
}

.newsletterSection-input {
  @include margin(auto);
  @include blue;
  position: relative;
  width: 70rem;
  max-width: 100%;

  input {
    @include placeholder($blue);
    @include font(light, italic);
    @include size(big5);
    padding: $gap-sm;
    width: 100%;
    border-bottom: .3rem solid $blue;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
