.zigzag {
    &-bottom {
        &:after {
            background: linear-gradient(-45deg, transparent 16px, $secondary-color 0), linear-gradient(45deg, transparent 16px, $secondary-color 0);
            background-repeat: repeat-x;
            background-position: left bottom;
            background-size: calc($spacer * 3 / 4) $spacer * 4;
            content: "";
            display: block;
            width: 100%;
            height: $spacer * 4;
        }
    }

    &-right {
        &:after {
            content:"";
            position: absolute;
            background: linear-gradient(45deg, $primary-color 5px, transparent 0) 0 5px,linear-gradient(135deg, $primary-color 5px, transparent 0) 0 5px;
            background-color: transparent;
            background-position: right top;
            background-repeat: repeat-y;
            background-size: 10px 10px;
            width: 10px;
            right: -10px;
            top: 0;
            bottom: 0;
        }
    }
}