////////////////// ARROW LINK //////////////////
.arrowLink {
  @include flex(row);
  display: inline-flex;
  align-items: center;

  @include font(bold);
  @include size(small);
  @include padding(right, $gap-md);
  position: relative;
  min-height: 4.2rem;
  text-transform: uppercase;
  color: inherit;
  @include opacity;

  @include icon('arrow-circle'){
    position: absolute;
    right: 0;
    top: 0;
    font-size: 4.2rem;
  }

  &:hover {
    text-decoration: none;
  }
}
