.lock-status {
    position: absolute;
    right: 0;
    top: 40%;
    font-size: 30px;
}

.icon-fc-lock {
    color: $red;
    font-size: 30px;

    &.small {
        font-size: 18px;
        margin-top: 2px;
    }

    &-open {
        color: $green;
    }
}