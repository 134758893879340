////////////////// ARTICLE LIST //////////////////

.listArticles-wrapper {
  @include margin(top, $gap-xl);
  @include margin(bottom, $gap-xl);
  align-items: flex-start;
}

.listArticles-category {
  @include padding(top, $gap);
  @include padding(bottom, $gap);
  border-right: 0.1rem solid darken($white, 10%);
}

.listArticles-item {
  & > div {
    display: inline-block;
    vertical-align: top;
  }

  // cloud
  & > figure.has-image-placeholder {
    width: 10rem !important;
    display: inline-block !important;
    padding-top: 14rem !important;
  }
}

.listArticles-img {
  @include padding(bottom, 1.6rem);
  @include padding(left, 1.8rem);
  width: 10rem;
  position: relative;
  @include icon('card-border'){
    position: absolute;
    bottom: 0;
    left: -1.5rem;
    @include green;
    font-size: 12rem;
  }

  .figure {
    padding-top: 140%;

    img {
      height: 100%;
      max-width: unset;
    }
  }
}

.dowload-pdf-block .figure.image-auto-width img:not(.image-picto) {
  width: unset;
}

.listArticles-content {
  @include padding(right, $gap);
  @include padding(left, $gap-sm);
  width: calc(100% - 12.5rem);
  .small-col & {
    width: 100%;
    padding: 0;
    margin-top: 1rem;
  }
}
