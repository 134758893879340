.triangle-top {
    $w-triangle: 16px;
    $h-triangle: 10px;

    position: relative;

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 calc($w-triangle / 2) $h-triangle calc($w-triangle / 2);
        border-color: transparent transparent rgba($secondary-color, 0.05) transparent;
        position: absolute;
        top: -$h-triangle;
        left: 4rem;
    }
}