.double-quote {
    &:before, &:after {
        content: "\e91e";
        font-family: fonticon!important;
        font-style: normal;
    }

    &:after {
        @include rotate(180deg);
    }
}