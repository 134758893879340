.text-single-date-day {
    font-size: 5rem;
    line-height: 5.2rem;
}

.agenda-next-event-block {
    .next-event-wrapper {
        margin-top: 5rem;

        h2 {
            position: absolute;
            top: 0;
        }

        @include media-breakpoint-up(xl){
            .next-envent-items-container {
                padding-left: $gap-md * 2;
                padding-right: $gap-md * 2;
            }
        }

        .date-wrapper {
            height: $h-agenda-date;
            width: $w-agenda-date;

            .text-big5 {
                line-height: 3rem;
            }
        }

        @include media-breakpoint-down(md){
            .next-envent-items-container {
                .col-md-4 {
                    margin-bottom: $spacer;
                }
            }
        }
    }
}