//////////////////////// CUSTOM CLASSES ////////////////////////
//////////////////////// TITLES
// in _config/typography.scss

.title {
  @include title;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}
//////////////////////// TEXT SIZES
// in _config/fonts.scss

.text-default {
  @include size(default);
}

.text-small {
  @include size(small);
}

.text-smaller {
  @include size(smaller);
}

.text-big1 {
  @include size(big1);
  .small-col & {
    @include size(default);
  }
}

.text-big2 {
  @include size(big2);
}

.text-big3 {
  @include size(big3);
}

.text-big4 {
  @include size(big4);
}

.text-big5 {
  @include size(big5);
}

.text-bigger1 {
  @include size(bigger1);
}

.text-bigger1 {
  @include size(bigger1);
}

.text-bigger2 {
  @include size(bigger2);
}

.text-bigger3 {
  @include size(bigger3);
}

.text-bigger4 {
  @include size(bigger4);
}

.text-bigger5 {
  @include size(bigger5);
}
//////////////////////// TEXT WEIGHTS
// in _config/fonts.scss

.light {
  @include font(light);
}

.bolder {
  @include font(bolder);
}
////////////////// ICONS
// create a class for each icons
// in _config/fonts.scss
@each $name, $value in $icons {
  .icon-fc-#{$name} {
    @include icon($name);
  }
}

.icon-arrow-up {
  @include icon-arrow(up);
}

.icon-arrow-right {
  @include icon(arrow);
}

.icon-arrow-bottom {
  @include icon-arrow(bottom);
}

.icon-arrow-left {
  @include icon-arrow(left);
}
//////////////////////// COLORS
// in _config/colors.scss

.blue {
  @include blue;
}

.darkblue {
  @include darkblue;
}

.darkgreen {
  @include darkgreen;
}

.green {
  @include green;
}

.bg-blue {
  background-color: $blue;
}

.bg-green {
  background-color: $green;
}

.bg-darkgreen {
  background-color: $green2 !important;
}

////////////////// TEXT DECORATIONS
// in atom/textDecorations.scss

.text-border {
  @include text-border;

  &.tags:before {
    height: 1.8rem;
  }
}

.text-border-bottom {
  position: relative;
  padding-right: $gap-sm;
  @extend .pl-0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2.5rem;
    height: 0.5rem;
    width: 4rem;
    background-color: $green;
  }
}

.text-border-bottom-full {
  position: relative;
  padding-bottom: $gap-sm;
  @extend .pl-0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.5rem;
    width: 100%;
    background-color: $green;
  }
}

.text-border-right-bolder {
  position: relative;
  padding-right: $gap-sm;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1rem;
    background-color: $green;
  }
}

.text-border-top-light {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0.1rem;
    width: 15rem;
    background-color: $border-color;
  }
}

.dash-decoration {
  @include dash-decoration;
}
////////////////// TAG
// in atom/tag.scss

.tag {
  @include tag;
}
////////////////// CAROUSEL
// in molecule/carousel.scss

.verticalCarousel {
  @include verticalCarousel;
}
////////////////// CITATION
// in molecule/citation.scss
.citation {
  @include citation;
}
.citation-author {
  @include citation-author;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.menu-box-shadow {
  box-shadow: $menu-box-shadow;
}

.flex-grow-1 {
  flex-grow: 1;
}

.text-transform-none {
  text-transform: none !important;
}

.flip {
  @include transform(scaleX(-1));
}

.rounded-medium {
  border-radius: 1rem;
}

// Slider's images
.slider-item-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bb-site-wrapper .small-col .paragraph {
  h1 {
    font-size: $font-size-bigger1 !important;
  }

  h2 {
    font-size: $font-size-big4 !important;
  }

  h3 {
    font-size: $font-size-default !important;
  }

  h4 {
    font-size: $font-size-small !important;
  }

  p {
    font-size: $font-size-default;
  }

  .citation {
    font-size: $font-size-big3 !important;
  }

  p.lead {
    font-size: $font-size-big1 !important;
  }

  .text-fc-small {
    font-size: $small-col-font-size-small !important;
    line-height: 1.2;
  }
}

.overflow-hidden {
  overflow: hidden;
}
