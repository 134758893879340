////////////////// BLOCK PRESS CONTACT //////////////////
////////////////// BORDERS
.contactBlock {
  @include gradientBorders;
  @include padding(top, $gap-md);
}
////////////////// TITLE

.contactBlock-title {
  @include font(bolder);
  @include size(bigger1);
  text-transform: uppercase;
  @include green;
  position: absolute;
  bottom: calc(100% - #{$gradientBorderDouble});
  right: -#{$gradientBorder};
  line-height: 2rem;
  @include icon(arrow) {
    @include size(bigger1);
    position: absolute;
    right: $gap;
    top: calc(100% - #{$gradientBorder});
    transform: rotate(135deg);
  }
}

.contactBlock-title-blue {
  @include blue;
  position: absolute;
  left: -#{$gap-md};
  bottom: calc(100% + 1rem);
}

.contactBlock-title-green {
  padding: 0 0 $gradientBorderDouble $gradientBorderDouble;
  background-color: $white;
}
////////////////// CONTENT
.contactBlock-name {
  @include font(light);
  @include size(big4);
  @include margin(bottom);
}
.contactBlock-phone {
  @include font(bold);
  @include blue;
  @include margin(bottom, $gap-sm);
  @include icon(phone){
    @include size(big2);
  }
}
.contactBlock-mail {
  @include size(small);
  @include icon(mail) {
    @include size(default);
  }
  p {
    @include margin(bottom, 1rem);

    &:last-of-type {
      @include margin(none);
    }
  }
}

.contactBlock-phone,
.contactBlock-mail {
  position: relative;
  padding-left: $gap;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
