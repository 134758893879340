.searchbar-in-page {
    input {
        padding: $gap-sm;
        padding-left: $gap-lg;
        padding-right: $gap-lg * 3;

        & + .search-btn {
            position: absolute;
            top: $spacer * 2;
            left: $spacer * 2;
        }

        &:focus {
            z-index: 0 !important;
            box-shadow: none;
        }
    }
}

.search-result-wrapper {
    @include transition($ui-transition);
}

// Search bar base documentaire

.search-bd {
    .placeholder {
        position: absolute;
        left: 3em;
        z-index: 1;
        top: .5rem;
    }
}

// Search result
.filter-checkbox > .filter-content-wrapper {
    margin-left: 1.5rem;
    border-left: 2px solid $secondary-color;
    padding-left: 1rem;
}

.filter-group > .filter-content-wrapper > .bg-light {
    padding: 2rem;
}