////////////////// CITATION //////////////////
@mixin citation {
  @include font(light);
  @include size(big5);
  font-style: italic;
}

@mixin citation-author {
  @include text-border;
  padding-left: 2.5rem;

  > * {
    display: block;

    &:first-child {
      @include font(bolder);
      @include size(big2);
    }
    &:not(:first-child){
      @include size(small);
    }
  }
}
