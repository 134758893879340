.block-analytic-matomo {
  p {
    line-height: normal;
    font-weight: 300;
  }
  input {
    appearance: auto !important;
    -webkit-appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    margin: 20px 10px 20px 0 !important;
  }
  label {
    display: inline;
  }
}