////////////////// LIST //////////////////
@mixin list($type : '', $icon : '') {
  @include margin(bottom);

  @if $type == number {
    counter-reset: orderedList;

    li {
      counter-increment: orderedList;

      &:before {
        content: counter(orderedList) ". ";
        @include font(bold);
        @include green;
      }
    }

  } @else if $type == icon {

    li {
      @include padding(left);
      position: relative;
      @include icon($icon){
        @include green;
        position: absolute;
        top: calc(50% - 0.75rem);
        left: 0;
      }
    }

  } @else {
    li {
      // @include padding(left, $gap-sm);
      position: relative;
      // display: flex;

      &:before {
        @include green-bg;
        content: '';
        // margin-top: .75rem;
        margin-right: 1rem;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        flex-shrink: 0;
        display: inline-block;
      }
    }
  }

  li {
    @include margin(bottom, 1rem);
  }
}

.listIcon--validate {
  @include list(icon, validate);
}
