// Block Chimie et Vous
$bg-height: 60rem;

.chimie-et-vous-block {
    position: relative;
    @include media-breakpoint-up(md) {
        height: $bg-height + $high-light-top;
    }

    .background-images {
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            top: 13rem;
            bottom: 0;
            right: 0;
            overflow: hidden;
        }

        .owl-carousel {
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                height: $bg-height;
            }
        }

        // Hide slide handle title
        .cloud-center {
            display: none;
        }

        .owl-nav {
            display: none;
        }
    }
}
