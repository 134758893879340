////////////////// TYPOGRAPHY CONFIGURATION //////////////////
////////////////// TEXT SIZES
@mixin size($size : default) {
	@if $size == default {
		font-size: $font-size-default !important;
	} @else	if $size == smaller {
		font-size: $font-size-smaller !important;
	} @else	if $size == small {
		font-size: $font-size-small !important;
	} @else	if $size == big1 {
		font-size: $font-size-big1 !important;
	} @else	if $size == big2 {
		font-size: $font-size-big2 !important;
	} @else	if $size == big3 {
		font-size: $font-size-big3 !important;
	} @else	if $size == big4 {
		font-size: $font-size-big4 !important;
	} @else	if $size == big5 {
		font-size: $font-size-big5 !important;

		@include media-breakpoint-down(md) {
			font-size: 1.8rem !important;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2rem !important;
		}
	} @else	if $size == bigger1 {
		font-size: $font-size-bigger1 !important;
	} @else	if $size == bigger2 {
		font-size: $font-size-bigger2 !important;
	} @else	if $size == bigger3 {
		font-size: $font-size-bigger3 !important;

		@include media-breakpoint-down(md) {
			font-size: 5rem !important;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.5rem !important;
		}
	} @else	if $size == bigger4 {
		font-size: $font-size-bigger4 !important;

		@include media-breakpoint-down(md) {
			font-size: 5.5rem !important;
		}
	} @else	if $size == bigger5 {
		font-size: $font-size-bigger5 !important;

		@include media-breakpoint-down(md) {
			font-size: 7rem !important;
		}
	}
}

////////////////// TITLES STYLES
@mixin title {
	text-transform: uppercase;
	@include margin(bottom, $gap-sm);
}
@mixin h1 {
	font-size: $font-size-bigger3;
	@include title;
	@include font(bolder);
	@include blue;
	@include margin(bottom, $gap);
}
@mixin h2 {
	font-size: $font-size-bigger1;
	@include title;
	@include font(bolder);
	@include darkblue;
	@include margin(bottom, $gap);
}
@mixin h3 {
	font-size: $font-size-big2;
	text-transform: uppercase;
	@include font;
	@include darkblue;
}
@mixin h4 {
	font-size: $font-size-default;
	text-transform: uppercase;
	@include font(bolder);
}
