////////////////// ANIMATIONS //////////////////
////////////////// HOVER ANIMATION
@mixin opacity {
  transition: 0.3s ease-out opacity;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    opacity: 0.5;
    @include media-breakpoint-down(md) {
      opacity: 1;
    }
  }
}

@mixin no-opacity {
  &:active,
  &:focus,
  &:hover {
    opacity: 1;
  }
}

a, button, [type="button"], [role="button"] {
  &:not(.disabled).opacity50 {
    @include opacity;
  }
}
////////////////// UNDERLINE ANIMATED
// @mixin underline-animated {
//   &::after {
//     content: '';
//     display: block;
//     border-bottom: 0.1rem solid;
//     transform: scaleX(1);
//     transform-origin: 0 50%;
//     transition: 0.3s ease-out transform;
//   }

//   &:active,
//   &:focus,
//   &:hover {
//     text-decoration: none;
//     &::after {
//       transform: scaleX(0);
//       transform-origin: 0 50%;
//       @include media-breakpoint-down(md) {
//         transform: scaleX(1);
//       }
//     }
//   }
// }
////////////////// TOGGLE ICON
@mixin toggleIcon($iconParent) {
  &[data-show] {
    #{$iconParent} {
      @include icon-arrow(bottom) {
        font-size: 1rem;
      }
    }

    &[aria-expanded="false"] {
      #{$iconParent} {
        @include icon-arrow(up);
      }
    }
  }
}
