//////////////////////// UTILITIES CLASSES ////////////////////////
/*
  Les classes, souvent composées de mixin, sont générées dans ce fichier afin d'être appelé en dernier dans le fichier css.
  Cela permet de créer un override de style plus efficace, sans recourir au tag !important.
*/
//////////////////////// GRID
// in _utils/mixin_grid.scss

.grid {
  @include grid;
}

.grid-sm {
  @include grid(small);
}
//////////////////////// SPACING MANAGEMENT
// in _utils/mixin_grid.scss
// MARGIN

// .multicolumns, .row {
.multicolumns {
  @include multicolumns;
}

.multicolumns-small {
  @include multicolumns(small);
}

.multicolumns-big {
  @include multicolumns(big);
}

.no-margin {
  @include margin(none);
}

.margin-auto {
  @include margin(auto);
}

.margin-top {
  @include margin(top);
}

.margin-right {
  @include margin(right);
}

.margin-bottom {
  @include margin(bottom);
}

.margin-left {
  @include margin(left);
}
// PADDING

.no-padding {
  @include padding(none);
}

.padding-top {
  @include padding(top);
}

.padding-right {
  @include padding(right);
}

.padding-bottom {
  @include padding(bottom);
}

.padding-left {
  @include padding(left);
}
//////////////////////// TEXT STYLES
// in _utils/mixin_typography.scss

.reset-text {
  @include reset-text;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
//////////////////////// DISPLAY MANAGEMENT
// in _utils/mixin_utils.scss
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.ovhidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.access-hidden {
  @include access-hidden;
}

.disabled {
  @include disabled;
}

.cover {
  @include cover;
}

.clearfix {
  @include clearfix;
}
//////////////////////// ALIGNMENT MANAGEMENT
// in _utils/mixin_utils.scss

.flex-center {
  @include flex(center);
}

.flex-vcenter {
  @include flex(vcenter);
}

.flex-column {
  @include flex;
}

.flex-row {
  @include flex(row);
}

.align-vertical {
  @include align(vertical);
}

.align-center {
  @include align(center);
}

.align-right {
  @include align(right);
}

.align-left {
  @include align(left);
}
//////////////////////// ANIMATION
// in _utils/mixin_animation.scss

.opacity {
  @include opacity;
}

.no-opacity {
  @include no-opacity;
}
