//////////////////////// UTILITIES MIXINS ////////////////////////
////////////////// DISPLAY MANAGEMENT
@mixin disabled {
  opacity: 0.5;
  pointer-events: none;
}
// hide the item but keep it readable by screen-readers
@mixin access-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);

  &::after,
  &::before {
    display: none;
  }
}
@mixin cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}
@mixin clearfix {
  &::after,
  &::before {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}
////////////////// ALIGNMENT MANAGEMENT
@mixin align($pos : center) {
  @if $pos == vertical {
    display: inline-block;
    vertical-align: middle;
  } @else {
    text-align: $pos;
  }
}
@mixin flex($pos : center) {
  display: flex;
  @if $pos == row {
    flex-direction: row;
  } @else {
    flex-direction: column;
    justify-content: center;
  }
  @if $pos == center {
    align-items: center;
  }
}
