nav.fixed-top {
    height: $h-nav-fixed-top;
    transition: margin-left .15s linear;
    @include transition($transition-navbar);

    i {
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
    }

    .menu-item-wrapper {
        .grid {
            padding: $gap-md $gap;
            min-height: $h-nav-fixed-top-level2;

            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: $gap;

                li {
                    padding: $spacer * 2 0;
                }
            }

            .menu-map {
                margin-top: 8rem;
                margin-bottom: 2rem;
            }
        }
    }

    .search-wrapper {
        flex-grow: 1;
        margin-top: $gap-lg;

        .searchbar-wrapper {
            margin-top: $gap-md;
            padding-bottom: $gap-md;
            border-bottom: 1px solid $secondary-color;

            input, .search-btn {
                background: transparent;
            }

            input {
                margin-left: 8rem;
                color: inherit;
                @extend .text-big5;
                @extend .light;

                &:focus, &:active, &.active {
                    z-index: 1;
                }
            }

            .placeholder {
                position: absolute;
                left: 9rem;
                top: 1rem;
                @extend .text-big5;
            }

            i {
                position: absolute;
                top: 1rem;
                @extend .text-bigger2;
            }
        }
    }

    // Cloud Toolbar
    .add-padding & {
        margin-left: 80px;
    }

    .small-screen.is-desktop .add-padding & {
        margin-left: 60px;
    }

    &.sticky {
        height: $h-nav-fixed-top-sticky;
    }
}

.logo-transaid {
    transition: margin-left .15s linear;
    height: 32px;

    .sticky & {
        height: 28px;
    }
}

.headerHome {
    position: relative;
    overflow: hidden;
    padding: 0;

    &-wrapper {
        z-index: 1;
        position: relative;
    }
}

.bg-video-wrapper {
    pointer-events: none;
    opacity: 0;

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color: #fff, $alpha: 0.3);
        z-index: 1;
    }
}

@media screen and (prefers-reduced-motion:reduce) {
    .bg-video-wrapper iframe {
        transition: none
    }
}

@media (max-width: 575.98px) {
    .bg-video-wrapper iframe {
        display:none
    }
}
