////////////////// FONTS MIXINS //////////////////
$fallback: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', 'Arial', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'sans-serif';
@mixin fontFace($name, $type, $weight : 400, $style : normal) {
  @font-face {
    font-family: $name;
    src: url("#{$fontPath}/#{$name}/#{$name}-#{$type}.eot");
    src: url("#{$fontPath}/#{$name}/#{$name}-#{$type}.eot?#iefix") format('embedded-opentype'),
         url("#{$fontPath}/#{$name}/#{$name}-#{$type}.ttf") format('truetype'),
         url("#{$fontPath}/#{$name}/#{$name}-#{$type}.woff") format('woff'),
         url("#{$fontPath}/#{$name}/#{$name}-#{$type}.svg##{$name}-#{$type}") format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}
////////////////// FONTICON
$fonticon : 'fonticon'; // default value, to change it go to _config/grid.scss
@mixin fonticon($fonticon, $token) {
  @font-face {
    font-family: $fonticon;
    src: url("#{$fontPath}/#{$fonticon}/#{$fonticon}.eot?#{$token}");
    src: url("#{$fontPath}/#{$fonticon}/#{$fonticon}.eot?#{$token}#iefix") format('embedded-opentype'),
         url("#{$fontPath}/#{$fonticon}/#{$fonticon}.ttf?#{$token}") format('truetype'),
         url("#{$fontPath}/#{$fonticon}/#{$fonticon}.woff?#{$token}") format('woff'),
         url("#{$fontPath}/#{$fonticon}/#{$fonticon}.svg?#{$token}##{$fonticon}") format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

// fonticon.scss -- Icons custom for project
// http://jaydenseric.com/blog/fun-with-sass-and-font-icons
@mixin icon($icon: false, $position: before, $styles: true) {
  @if $position == both {
    $position: 'before, &:after';
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &:#{$position} {
    @if $icon {
      // A particular icon has been specified
      content: "#{map-get($icons, $icon)}";
    }
    @if $styles {
      // Supportive icon styles required
      font-family: '#{$fonticon}' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: inherit;
      color: inherit;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Screenreaders should ignore that kind of elements =========== */
      -webkit-alt: "";
      speak: none;
      display: block;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}
