// Navbar toggle
.nav-mobile {
    position: fixed;
    z-index: $z-index-menu;
    box-shadow: $menu-box-shadow-mobile;
    @include media-breakpoint-down(sm) {
        background: $white;
    }

    .navbar {
        height: $h-navbar-mobile;
    }

    .navbar-brand {
        height: 100%;
    }

    &_toggle {
        border-width:0;
        padding: 14px 2rem;
        height: $h-navbar-mobile;
        width: $h-navbar-mobile;
        position: absolute;
        right: 0;
        z-index: $z-index-menu + 2;
        top: 0;

        .icon-bar {
            $icon-bar-height: 2px;

            background-color: $white;
            display: block;
            width: 2rem;
            height: $icon-bar-height;
            @include transition($ui-transition);
            @include transform(rotate(0deg));

            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: $icon-bar-height;
                background-color: $white;
                border-radius: 1px;
            }

            &:before {
                top: -7px;
            }

            &:after {
                top: 7px;
            }
        }

        &.menu-shown {
            .icon-bar {
                background: transparent;

                &:before{
                    @include transition($ui-transition);
                    @include transform(rotate(45deg) translate(5px,5px));
                }

                &:after{
                    @include transition($ui-transition);
                    @include transform(rotate(-45deg) translate(5px,-5px));
                }
            }
        }
    }

    &_nav {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100vh;
        z-index: $z-index-menu + 1;
        @include transform(translateX(-100%));
        @include transition($ui-transition);

        &.show {
            @include transform(translateX(0));
        }

        .search-wrapper {
            flex-grow: 1;

            .searchbar-wrapper {
                margin: 0 $gap;
                border-bottom: 1px solid $secondary-color;
                color: white;
                @extend .pb-4;

                input, .search-btn {
                    background: transparent;
                }

                input {
                    margin-left: 4rem;
                    color: inherit;
                    @include size(big2);

                    &:focus, &:active, &.active {
                        z-index: 1;
                    }
                }

                .placeholder {
                    font-size: 0;
                    &:before {
                        content: 'RECHERCHE';
                        color: $secondary-color;
                        font-style: initial;
                        font-weight: 400;
                        position: absolute;
                        top: 1rem;
                        left: 5rem;
                        @include size(big2);
                    }

                }

                i {
                    position: absolute;
                    top: 1rem;
                    @extend .text-big4;
                    color: white;
                }

                .input-group-append {
                    display: none;
                }
            }
        }

        .darkgreen:hover {
            a {
                color: $green2;
            }
        }
    }

    .nav-mobile-wrapper {
        position: relative;
        flex-grow: 1;
        width: 100%;
    }

    &_nav-level-1 {
        padding: $gap;
        width: 100%;
        max-height: calc(100vh - 180px);
        overflow-y: auto;

        li {
            margin-bottom: $gap;
            width: 100%;

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;
            }
        }
    }

    &_nav-level-2, &_nav-level-3 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: $h-navbar-mobile;
        z-index: 1;
        display: flex;
        flex-direction: column;
        @include transform(translateX(-100%));
        @include transition($ui-transition);

        &.show {
            @include transform(translateX(0));
        }

        .wrap-nav {
            flex-grow: 1;
            max-height: calc(100vh - 180px);
            overflow-y: auto;
        }

        a.back-to {
            justify-content: flex-start;

            i {
                @include rotate(180deg);
            }
        }

        a {
            text-transform: initial;
            font-weight: 400;
        }
    }

    &_footer-nav {
        position: fixed;
        bottom: 0;
        padding: 0;
        flex-wrap: nowrap;
        z-index: 1;
        left: 0;
        right: 0;

        .nav-item {
            border: 0;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            font-size: 1.2rem;

            i {
                font-size: 2rem;
                margin-bottom: 4px;
            }
        }
    }

    .mobile-rs-wrapper, .mobile-sites-wrapper {
        @include transition($ui-transition);
        position: absolute;
        bottom: $h-navbar-mobile;
        top: 200vh;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.show {
            top: $h-navbar-mobile;
            z-index: 1;
            overflow-y: auto;
        }

        .nav-item {

            &, & a {
                display: flex;
                flex-direction: column;
            }

            i {
                @extend .mb-3
            }
        }

        .nav-link {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            @extend .bolder;
        }
    }

    .btn-mobile-rs, .btn-mobile-sites {
        &.show-close {
            margin-top: -1px;
        }
    }

    li.darkgreen a {
        @include transition($ui-transition);

        &:hover {
            color: $white;
        }
    }

    &-logo {
        width: 120px;

        figure {
            padding-top: 70%;
        }
    }

    .has-image-placeholder {
        min-width: 90px;

        .label {
            display: none;
        }

        .ico {
            height: 40px;
            width: 40px;
            line-height: 42px;
        }
    }
}

.h-6rem {
    height: 6rem;
}

// Cloud
.is-desktop {
    .cloud-edit.add-padding {
        .nav-mobile {
            width: calc(100% - 80px) !important;
        }

        .navbar {
            padding-left: 0 !important;
        }
    }
}
