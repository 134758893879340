// Block banner
$bg-height: 60rem;
$banner-top: 2rem;

$h-vertical-line: 14rem;
$w-vertical-line: .2rem;

$w-high-light: 44rem;

.banner-block {
    position: relative;
    @include media-breakpoint-up(md) {
        height: $bg-height + $banner-top;
        &:before {
            content: '';
            background-color: $green;
            display: block;
            height: $h-vertical-line;
            left: 50%;
            position: absolute;
            width: $w-vertical-line;
            z-index: 2;
        }
    }

    .background-images {
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            top: $banner-top;
            bottom: 0;
            right: 0;
        }

        .owl-carousel {
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                height: $bg-height;
            }
        }

        // Hide slide handle title
        .cloud-center {
            display: none;
        }

        .owl-nav {
            display: none;
        }
    }

    .sectionHighlight-content {
        width: $w-high-light;
        padding: $gap-lg;

        @include media-breakpoint-down(sm) {
            padding: $spacer;
        }

        &.zigzag-right:after {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .sectionHighlight-text {
            background: transparent !important;
        }
    }

    @include media-breakpoint-down(md) {
        .sectionHighlight {
            width: 100%;
        }
    }
}