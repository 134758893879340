////////////////// CORE //////////////////

html {
	font-size: 62.5%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	@include font;
	@include size;
	position: relative;
	color: $text;
	line-height: 1.4;
	background-color: $white;
	overflow-x: hidden;
}
