//////////////////////// RESET CUSTOM ////////////////////////

*,
*:after,
*:before {
	box-sizing: border-box;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, em, img, ol, ul, li, label, table, tbody, tfoot, thead, tr, th, td, article, aside, footer, header, menu, nav, output, section, audio, video, figcaption, figure, fieldset {
  margin: 0px;
  padding:0px;

  border: 0;

  // font: inherit;
  vertical-align: baseline;

  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;

  -webkit-margin-start: 0em;
  -webkit-margin-end: 0em;

  -webkit-padding-start: 0em;
  -webkit-padding-end: 0em;

  -webkit-padding-before: 0em;
  -webkit-padding-after: 0em;
}


article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, label {
  display: block;
}

button, input, select {
  display: inline-block;
}

i {
  font-style: normal;
}

u {
  text-decoration: none;
  border-width: 0 0 1px;
  border-style: solid;
}

ol, ul {
  list-style: none;
}

button, input, select, textarea {
  padding: 0;
  margin: 0;
  border: 0;
	color: inherit;

  font: inherit;
  background: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

label, select, button, a {
  cursor: pointer;
}

a, button, input, select {
  border-radius: 0;
}

a {
	&,
	&:link,
	&:active,
	&:focus,
	&:hover,
	&:visited {
		color: inherit;
		text-decoration: none;
	}
}

img {
  display: block;
  // width: 100%;
  max-width: 100%;
  height: auto;
}

input[type="radio"],
input[type="checkbox"],
input[type="button"] {
  margin: 0 !important;
  padding:0 !important;

  height: auto !important;
}

blockquote, q { quotes: none; }

blockquote:before,
blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

address {
  font-style: normal;
}
