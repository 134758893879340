/* Custom variables below */

$high-light-top:                9rem; //$gap-xl

$h-nav-fixed-top:               6rem;
$h-nav-fixed-top-sticky:        3rem;
$h-mega-menu:                   9rem;
$h-mega-menu-sticky:            6rem;
$h-nav-fixed-top-level2:        50rem;

$h-navbar-mobile:               6rem;

$h-agenda-date:               12rem;
$w-agenda-date:               10rem;

$border-radius:                 0;
$border-radius-lg:              0;
$border-radius-sm:              0;

$menu-box-shadow:               0 12px 20px rgba(#000, .2);
$menu-box-shadow-mobile:        0 2px 12px rgba(#000, .2);

$z-index-menu:                  9989;

$modal-backdrop-opacity:        .3;

$border-color:                $gray-300;

$ui-transition:                 all 250ms ease;

$input-border-radius:           0;
$custom-select-border-radius:   0;

// Grid
$grid: 142rem;
$grid-sm: 120rem;

// Spacing
$gap: 3rem;
$gap-sm: 1.5rem;
$gap-md: 5rem;
$gap-lg: 7rem;
$gap-xl: 9rem;
$gap-xxl: 11rem;
$gap-xxxl: 12rem;


$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5),
  7: ($spacer * 10)
);

$font-size-default:             1.6rem;
$font-size-small:               1.4rem;
$small-col-font-size-small:     1.2rem;
$font-size-smaller:             1rem;
$font-size-big1:                1.8rem;
$font-size-big2:                2rem;
$font-size-big3:                2.2rem;
$font-size-big4:                2.4rem;
$font-size-big5:                2.8rem;
$font-size-bigger1:             3rem;
$font-size-bigger2:             3.5rem;
$font-size-bigger3:             5.5rem;
$font-size-bigger4:             7rem;
$font-size-bigger5:             14.7rem;

$container-max-widths: (
  xl: 1200px
)