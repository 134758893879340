/* Custom theme below */

$primary-color:             #243387;
$secondary-color:           #0acc82;
$text-color:                #344154;
$background-color:          #ffffff;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "dark": $text-color,
    "secondary-transparent": rgba($secondary-color, 0.05)
)
