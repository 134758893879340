////////////////// GRADIENT BORDERS //////////////////
$gradientBorder: 0.6rem;
$gradientBorderDouble: 1.2rem;
////////////////// BORDERS
@mixin gradientBorders {
  padding: $gradientBorder;

  & > [class$="-wrapper"] {
    padding: $gap;
    position: relative;
    background-color: $white;

    &:before {
      content: '';
      position: absolute;
      top: -#{$gradientBorder};
      left: -#{$gradientBorder};
      width: calc(100% + #{$gradientBorderDouble});
      height: calc(100% + #{$gradientBorderDouble});
      z-index: -1;
      background: $green;
      background: -moz-linear-gradient(-45deg, $green2 0%, $green 50%, $blue 100%);
      background: -webkit-linear-gradient(-45deg, $green2 0%,$green 50%,$blue 100%);
      background: linear-gradient(135deg, $green2 0%,$green 50%,$blue 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$green}', endColorstr='#{$blue}',GradientType=1 );
    }
  }
}
