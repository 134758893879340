.industrial-map-block {
  .industrial-map-list {
      position: relative;

      ul {
          display: flex;
          flex-wrap: wrap;
          margin-top: $spacer * 2;

          li {
              .industrial-map-list-item {
                  @include transition($ui-transition);

                  &.active {
                      color: $green;
                  }
              }
          }
      }
  }

  .map-wrapper {
      padding-right: 8rem;

      ul {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 44rem;
          z-index: 1;

          li {
              position: absolute;
          }
      }

      .info-bubble {
          position: absolute;
          opacity: 0;
          visibility: hidden;
          width: 32rem;
          left: 4rem;
          bottom: 1.6rem;
          z-index: 1;
          @include transition($ui-transition);

          .bg-primary:before {
              content: '';
              position: absolute;
              left: -1rem;
              bottom: .5rem;
              width: 3rem;
              border-top: .2rem solid #0acc82;
              transform: rotate(135deg);
              z-index: 1;
          }

          &.show {
              opacity: 1;
              visibility: visible;
          }

          .btn-close {
              position: absolute;
              padding: 1.6rem;
              top: 0;
          }

          a[href^="mailto:"] {
              text-decoration: underline;

              &:hover {
                  color: $white;
                  text-decoration: none;
              }
          }
      }
  }

  .region-mark {
      cursor: pointer;
  }

  .map {
      width: 28rem;
  }

  .mt-3 {
      a.btn {
          padding: 0;

          &:hover {
              color: $green;
          }
      }
  }
}

@include media-breakpoint-down(md) {
  .region-list {
      ul {
          border: none !important;
      }
  }
}

.industril-map-item {
    padding-top: 100px;
    margin-top: -100px;

    ul, ol {
        list-style: disc;
        margin: 2rem;
    }

    a {
        color: $green;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .cloud-edit & {
        padding-top: 0;
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        &.image-right {
            .row {
                flex-direction: row-reverse;
            }
        }
    }
}

.location {
    &-bazancourt-pomacle {
        left: 180px;
        top: 40px;
    }

    &-chemesis {
        left: 220px;
        top: 52px;
    }

    &-lyon-vallee-de-la-chimie {
        left: 184px;
        top: 158px;
        z-index: 1;
    }

    &-berre {
        left: 188px;
        top: 226px;
    }

    &-lavera {
        left: 180px;
        top: 236px;
    }

    &-port-jerome-normandy {
        left: 105px;
        top: 32px;
    }

    &-chempole-64 {
        left: 63px;
        top: 230px;
    }

    &-piicto {
        left: 188px;
        top: 241px;
    }

    &-synerzip-le-havre {
        left: 90px;
        top: 29px;
    }

    &-induslacq {
        left: 63px;
        top: 238px;
        z-index: 1;
    }

    &-grenoble-chemical-park {
        left: 202px;
        top: 180px;
    }

    &-villers-saint-paul {
        top: 42px;
    left: 140px;
    }

    &-paradis-nogueres {
        left: 63px;
        top: 248px;
    }

    &-lamotte {
        top: 38px;
        left: 160px;
    }

    &-weurope {
        left: 253px;
        top: 86px;
    }

    &-commentry {
        top: 150px;
        left: 150px;
    }

    &-les-roches-roussillon {
        left: 175px;
        top: 175px;
    }

    &-balan {
        left: 201px;
        top: 159px;
    }
}

.industrial-popin-info {
    &:not(.show) {
        display: none;
    }

    left: 0;
    top: 0;
    box-shadow: $menu-box-shadow-mobile;
    -webkit-box-shadow: $menu-box-shadow-mobile;
    min-height: 280px;
    z-index: 1;

    .container {
        padding: 2rem;

        ul {
            display: block;
            margin: 2rem;
            list-style: disc;
            margin: 2rem;
        }
    }
}
