////////////////// FONTS CONFIGURATION //////////////////
$fontPath: 'fonts';
////////////////// FONTS //////////////////
$font: 'Roboto';
@include fontFace($font, 'Light', 100);
@include fontFace($font, 'LightItalic', 100, italic);
@include fontFace($font, 'Medium', 400);
@include fontFace($font, 'MediumItalic', 400, italic);
@include fontFace($font, 'Bold', 700);
@include fontFace($font, 'BoldItalic', 700, italic);
@include fontFace($font, 'Black', 900);
@include fontFace($font, 'BlackItalic', 900, italic);
@include fonticon('fonticon','1a79we');
////////////////// TEXT WEIGHTS
@mixin font($style : default, $italic: false, $name : $font) {
	font-family: '#{$name}', $fallback;
	@if $style == default {
		font-weight: 400;
	} @else
	if $style == light {
		font-weight: 100 !important;
	} @else
	if $style == bold {
		font-weight: 700;
	} @else
	if $style == bolder {
		font-weight: 900;
	}
	@if $italic {
		font-style: italic;
	}
}
////////////////// ICONS //////////////////
$icons: (
  price: "\e921",
  close-alt: "\e91f",
  quote: "\e91e",
  location: "\e91d",
  share: "\e91c",
  phone: "\e91b",
  users: "\e900",
  cells: "\e901",
  lock: "\e902",
  lock-open: "\e903",
  card: "\e904",
  close: "\e905",
  validate: "\e906",
  shutdown: "\e907",
  card-border: "\e908",
  card-borders: "\e909",
  card-borders-wide: "\e90a",
  mail: "\e90b",
  facebook: "\e90c",
  card-facebook: "\e90d",
  arrow: "\e90e",
  card-arrow: "\e90f",
  arrow-circle: "\e910",
  people: "\e911",
  pdf: "\e912",
  linkedin: "\e913",
  france: "\e914",
  searching: "\e915",
  map: "\e916",
  docs: "\e917",
  twitter: "\e918",
  card-twitter: "\e919",
  youtube: "\e91a",
  file: "\e920"
);
////////////////// CUSTOM ICONS //////////////////
@mixin icon-arrow($pos : right) {
  @include icon(arrow) {

    @if $pos != right {
      position: relative;
      @if $pos == up {
        top: -.1rem;
        transform: rotate(-90deg);
      } @else if $pos == left {
        left: -.5rem;
        transform: rotate(180deg);
      } @else if $pos == bottom {
        top: 0.5rem;
        transform: rotate(90deg);
      }
    }

    @content;
  };
}

.icon-arrow-outline {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  margin: 0 1rem;
  flex-shrink: 0;

  &:before, &:after {
    content:'';
    top: .5rem;
    position: absolute;
    width: .75rem;
    height: .2rem;
    background-color: $text-color;
    display: inline-block;
    transition: all .2s ease;
  }

  &:before {
    left: 0;
    transform: rotate(45deg);
  }

  &:after {
    right: 0;
    transform: rotate(-45deg);
  }

  &.active {
    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  &.darkgreen {
    &:before, &:after {
      background-color: $secondary-color;
    }
  }

  &.green {
    &:before, &:after {
      background-color: $green;
    }
  }

  &.primary-color {
    &:before, &:after {
      background-color: $primary-color;
    }
  }

  &.lighter-line {
    &:before, &:after {
      height: 0.1rem;
    }
  }

  &.smaller {
    width: .8rem;
    height: .8rem;
    &:before, &:after {
      width: .5rem;
    }
  }

  &.white {
    &:before, &:after {
      background-color: $white;
    }
  }

  &.right {
    transform: rotate(-90deg);
  }

  &.left {
    transform: rotate(90deg);
  }

  &.top {
    transform: rotate(-180deg);
  }
}
