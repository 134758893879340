////////////////// TEXT DECORATIONS //////////////////
@mixin text-border {
  position: relative;
  padding-left: $gap-sm;

  &:before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    height: 100%;
    width: 0.5rem;
    background-color: $green;
  }
}
@mixin dash-decoration {
  @include margin(left, $gap);
  @include margin(bottom, $gap);
  position: relative;
  @include media-breakpoint-down(md) {
    margin: -1px;
    overflow-y: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    left: -8.5rem;
    bottom: 0;
    width: 17rem;
    border-top: 0.2rem solid $green;
    transform: rotate(135deg);
    z-index: 2;
  }
}
