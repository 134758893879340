////////////////// SLIDER ITEMS //////////////////
.sliderItems-wrapper {
  @include grid(small);
  @include margin(top, $gap-md);
}

.sliderItems-item {
  @include sliderWrapper;
}

.sliderItems-image {
  min-height: 25rem;
}

.sliderItems-content {
  padding: $gap-md;
  background-color: darken($white, 5%);
}

.owl-item .cloud-edit-content {
    display: none;
}
