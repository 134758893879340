////////////////// COLORS CONFIGURATION //////////////////
$white     : #ffffff;
$green     : #0acc82;
$green2    : #6ab94b;
$blue      : #243387;
$blue2     : #344154;

$text      : $blue2;
////////////////// COLORS MIXINS
@mixin green {
	color: $green;
}
@mixin blue {
	color: $blue;
}
@mixin darkblue {
	color: $blue2;
}
@mixin blue-bg {
	background-color: $blue;
}
@mixin green-bg {
	background-color: $green;
}
@mixin darkgreen {
	color: $green2 !important;
}

@mixin darkgreen-bg {
	background-color: $green2;
}
