//////////////////////// FORM ////////////////////////
@mixin placeholder($color : grey) {
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

// Checkbox Radio
.custom-radio {
  $size-check-radio: 1.2rem;

  .custom-control-label {
    &:before {
      height: $size-check-radio;
      width: $size-check-radio;
      background-color: transparent;
      border: 1px solid $border-color;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background-color: $green;
      border-color: $green;
    }

    &:after {
      display: none;
    }
  }

  .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
  }
}

.required {
  &:after {
    content: '*';
    font-size: 1.2rem;
    margin-left: .5rem;
    position: absolute;
  }
}

.custom-input,
.custom-textarea {
  position: relative;
  margin: {
    bottom: $spacer * 3;
    top: $spacer * 2;
  }

  .custom-input-label {
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 1;
    @include transition(transform 150ms ease-out, font-size 150ms ease-out);
  }

  input,
  textarea {
    padding: 2rem;

    &:focus,
    &[required]:valid,
    &.is-filled {
      & ~ .custom-input-label {
        transform: translate(-2rem, -4rem);
        font-size: .875em;
        color: $text-muted;
      }
    }

    &:-webkit-autofill {
      & ~ .custom-input-label {
        transform: translate(-2rem, -4rem);
        font-size: .875em;
        color: $text-muted;
      }
    }
  }

  input {
    height: 6rem;
  }

  textarea.not-resizable {
    resize: none;
  }
}

.custom-select-fc {
  &,
  &.focus,
  &:focus {
    background: transparent;
    height: 6rem !important;
    padding: 2rem;
    position: relative;

    margin: {
      bottom: $spacer * 3;
      top: $spacer * 2;
    }
  }

  & ~ .custom-select-arrowDown {
    height: 6rem;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 6rem;
    pointer-events: none;
  }
}

// Select Sort by
.icon-sort {
  position: absolute;
  right: 6px;
  pointer-events: none;
}

input.ui-autocomplete-input {
  height: auto;
}