.article-header {
    i {
        font-size: 16rem;
    }

    label {
        position: absolute;
        top: 6rem;
        right: -2rem;
        max-width: 16rem;
    }

    @include media-breakpoint-down(sm) {
        .wrap-withicon {
            flex-flow: wrap;
        }

        i {
            font-size: 12rem;
        }

        label {
            top: 4.5rem;
            font-size: 1.8rem !important;
        }
    }

    @include media-breakpoint-down(xs) {
        .wrap-withicon {
            flex-flow: wrap;
        }

        i {
            font-size: 8rem;
        }

        label {
            top: 2.8rem;
            font-size: 1.4rem !important;
        }
    }
}