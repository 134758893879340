////////////////// BLOCK ZOOM //////////////////
.zoomBlock {
  @include gradientBorders;
  @include padding(right, $gap);
  @include padding(top, $gap-xxxl);

  & > [class$="-wrapper"] {
    @include padding(top, $gap-md);
  }
}

.zoomBlock-title {
  @include flex(row);
  @include padding(left, $gap-md);
  @include padding(bottom, $gap-sm);
  @include font(bolder);
  @include size(big3);
  @include blue;
  position: absolute;
  bottom: calc(100% - #{$gap});
  right: -#{$gap};
  text-transform: uppercase;
  background-color: $white;
  white-space: nowrap;
}

.zoomBlock-titleText {
  position: absolute;
  left: -5%;
  bottom: 20%;
  width: 100%;
  height: 100%;
  transform: rotate(270deg);
}

.zoomBlock-img {
  @include flex(center);
  @include blue-bg;
  padding: $gap-sm;
  width: 13.5rem;
  height: 13.5rem;
}

.zoomBlock-content {
}
