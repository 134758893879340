////////////////// CAROUSEL //////////////////
@mixin verticalCarousel {
  .owl-carousel {
    position: relative;
    .owl-dots {
      @include flex(center);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      @include media-breakpoint-down(md) {
        display: block;
        position: static;
      }
    }
  }
}

@mixin sliderWrapper($size : 50) {
  @include flex(row);
  width: 100%;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  > div {
    width: #{$size + '%'};
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.owl-carousel {
  @include margin(bottom, 4rem);
  .owl-dots {
    // background-color: $white;

    .owl-dot {
      @include no-opacity;
      span {
        margin: .3rem;
        width: 1.2rem;
        height: 1.2rem;
        border: .2rem solid darken($white, 10%);
        background-color: darken($white, 5%);
        border-radius: 50%;
      }

      &.active, &:hover {
        span {
          border-color: $green;
          background-color: $green !important;
        }
      }
    }
  }

  .slider-item {
    .figure img {
      @include transform(translate3d(-50%,-50%,0) scale(1));

      @include media-breakpoint-up(md) {
        @include transform(translate3d(-50%,-50%,0) scale(1.3));
        top: 40%;
      }
    }
  }
}
