//////////////////////// GRID MIXINS ////////////////////////
////////////////// GRID
@mixin grid($size : default) {
  @include margin(auto);
  @if $size == small {
    width: $grid-sm;
    max-width: calc(100% - (#{$gap-md} * 2));
  } @else {
    width: $grid;
    max-width: calc(100% - (#{$gap} * 2));
  }
}
////////////////// SPACING
@mixin multicolumns($size : default) {
  $margin: $gap;
  @if $size == small {
    $margin: $gap-sm;
  } @else if $size == big {
    $margin: $gap-md;
  }
  @include margin(right, -#{$margin});
  @include margin(left, -#{$margin});

  & > * {
    @include padding(right, $margin);
    @include padding(left, $margin);
  }
}
@mixin margin($type, $size : $gap) {
  @if $type == auto {
    margin-right: auto;
    margin-left: auto;
  } @else if $type == none {
    margin: 0;
  } @else if $type == top {
    margin-top: $size;
  } @else if $type == right {
    margin-right: $size;
  } @else if $type == bottom {
    margin-bottom: $size;
  } @else if $type == left {
    margin-left: $size;
  }
}
@mixin padding($type, $size : $gap) {
  @if $type == none {
    padding: 0;
  } @else if $type == top {
    padding-top: $size;
  } @else if $type == right {
    padding-right: $size;
  } @else if $type == bottom {
    padding-bottom: $size;
  } @else if $type == left {
    padding-left: $size;
  }
}
