////////////////// HOME HEADER //////////////////
.headerHome {
  margin-top: -1px;

  @include media-breakpoint-up(lg) {
    // margin-top: $h-nav-fixed-top;
  }

  @include media-breakpoint-down(sm) {
    &.cover {
      background-position-x: 80%
    }

    &.is-home {
      @include margin(bottom, $gap-xl);
    }
  }

  .megamenu {
    @include grid(small);
    background-color: $primary-color;
    color: $white;
    padding-left: $gap-md;
    padding-right: $gap-md;
    height: $h-mega-menu;
    // @include transition($transition-navbar);
    @include transition(height 100ms ease-in-out);
    z-index: 1;

    &:not(.sticky) {
      justify-content: space-around;

      .logo {
        display: none;
      }
    }

    .megamenu-nav {
      flex-grow: 1;

      ul.nav {
        justify-content: space-evenly;
      }
    }

    ul {
      @include transition($transition-navbar);
      flex-wrap: nowrap;
    }

    .logo {
      width: 70px;
    }

    .nav-item {
      flex-grow: 1;
      text-align: center;
      font-size: 1.3rem;
      max-width: 16rem;

      .nav-link {
        padding: 0 2rem;
        height: $h-mega-menu;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        white-space: unset;
        @extend .rounded-0;
        @include transition(height 250ms ease-in-out, background-color 250ms ease-in-out);

        &.active {
          background-color: $secondary-color;
        }
      }
    }

    &.sticky {
      background-color: $primary-color;
      height: $h-mega-menu-sticky;
      margin: 0;
      position: fixed;
      top: $h-nav-fixed-top-sticky;
      width: 100%;
      z-index: $z-index-menu;
      max-width: unset;

      .nav {
        margin: 0 auto;
        flex-grow: unset;
        justify-content: flex-start;

        @include media-breakpoint-up(lg) {
          max-width: $grid;
        }
      }

      .nav-item {
        flex-grow: 1;

        &.logo {
          flex-grow: unset;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .nav-link {
        height: $h-mega-menu-sticky;

        &.btn.color-darkgreen {
          background-color: $primary-color;
          color: $green2;

          &:hover {
            color: $white;
            background-color: $green2;
          }

          &.active {
            color: $white;
            background-color: $green2;
          }
        }
      }
    }

    &-nav {
      .logo {
        width: 90px;

        figure {
          padding-top: 70%;
        }
      }
    }
  }

  .megamenu-nav-wrapper {
    position: absolute;
    z-index: $z-index-menu;
    color: $text-color;

    .submenu-level-1 {
      padding-top: $gap;
      padding-bottom: $gap;
      @extend .border-bottom;
      a {
        text-transform: unset;
      }
    }

    .grid {
      padding: $gap-md $gap;
      min-height: $h-nav-fixed-top-level2;
    }

    ul.submenu-level-2 {
      display: flex;
      flex-wrap: wrap;
      margin-top: $gap;

      li {
          padding: $spacer 0;
      }
    }

    ul.submenu-level-3 {
      @extend .py-3;
    }
  }
}

.headerHome-wrapper {
  @include grid(small);
  padding-top: $gap-lg;
  height: 100%;
  margin-top: $h-nav-fixed-top;
  @include media-breakpoint-down(sm) {
    padding-top: $gap-xxl;
    padding-bottom: 0;
  }
}

.headerHome-logo {
  position: relative;
  left: -#{$gap-xl};
  width: 25rem;
  max-width: 100%;
  margin-bottom: 3rem;

  @include media-breakpoint-down(xl) {
    left: 0;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }

  figure {
    padding-top: 40%;
  }
}

.headerHome-content {
  padding-left: $gap-md;
  max-width: 100%;

  @include media-breakpoint-down(md) {
    padding-left: 0;
  }

  @include media-breakpoint-down(sm) {
    display: inline-block;
    position: relative;
    left: -#{$gap-md};
    bottom: -#{$gap-xl};
    padding: $gap;
    background-color: $white;
  }
}

.headerHome-title {
  @include font(bolder);
  @include size(bigger3);
  text-transform: uppercase;
  width: 50rem;
  max-width: 100%;
  @include media-breakpoint-down(sm) {
    width: 31rem;
  }

  p {
    line-height: 1.2;
  }
}

.headerHome-link {
  @include blue;
  @include margin(top);
}
