////////////////// BICOLOR TITLE //////////////////
@mixin bicolorTitle {
  @include font(bolder);
  @include size(bigger4);
  text-transform: uppercase;
  line-height: 7rem;
  @include media-breakpoint-down(md) {
    line-height: 6rem;
  }

  .green, .blue {display: block;}

  .green {
    padding-left: $gap-md;
  }
}

.bicolorTitle {
  @include bicolorTitle;
}
