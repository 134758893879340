////////////////// SCROLL TO TOP //////////////////

#scroll-to-top {
  @include opacity;
  @include flex(center);

  visibility: hidden;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  transform: translateX(100%);
  transition: all 0.3s ease-out;

  @include blue;
  @include icon(card-arrow);
  width: auto;
  font-size: 5rem;
  background-color: $white;

  &.show {
    visibility: visible;
    transform: translateX(0%);
  }
}
