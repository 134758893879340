////////////////// SECTION HIGHLIGHT //////////////////
.sectionHighlight-wrapper {
  @include media-breakpoint-up(md) {
    @include flex(row);

    &.right {
      justify-content: flex-end;
    }
  }

}

.sectionHighlight {
  @include media-breakpoint-up(xl) {
    padding: 0 20rem;
  }
  @include media-breakpoint-down(md) {
    display: block;
    margin: $gap 0;
    padding: 0;
  }
}

.sectionHighlight-content {
  padding: $gap $gap * 2 $gap-xl;
  position: relative;
  width: 48rem;
  max-width: 100%;
  background-color: $white;
  z-index: 2;

  @include media-breakpoint-up(xl) {
    padding: $gap $gap-lg $gap-xl;
    width: 60rem;
  }

  @include media-breakpoint-down(md) {
    width: 40rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    position: static;
    width: 100%;
    background-color: transparent;
    &:before {
      display: none;
    }
  }

  &.with-dash {
    @include dash-decoration;
  }
}

.sectionHighlight-title {
  @include bicolorTitle;
}

@include media-breakpoint-down(sm) {
  .sectionHighlight-title {
    padding: 0 0 $gap-sm $gap-sm;
    // margin-bottom: 30rem;
    background-color: $white;
  }

  .sectionHighlight-text {
    padding: $spacer * 2;
    background-color: $white;
  }
}
