.filter-checkbox {
    a {
        @extend .vCenter;
        @extend .mb-4;

        &:before {
            content: '';
            display: block;
            flex-shrink: 0;
            height: $spacer * 2;
            width: $spacer * 2;
            border: 2px solid $border-color;
            @extend .mr-4;
        }
    }

    &.selected a:before {
        background-color: $secondary-color;
        border-color: $secondary-color;
        box-shadow: 0px 4px 4px #ddd;
    }

    &.checkbox-indeterminante {
        & > a::before {
            content: '—';
            color: $white;
            font-weight: bold;
            border: 0;
            text-align: center;
            background-color: $secondary-color;
        }
    }

    &.position-relative {
        a {
            margin-right: 20px;
        }

        .filter-toggler {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
