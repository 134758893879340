// Custom CSS RESET

html {
    scroll-behavior: smooth;
}

.bb-site-wrapper {
    .navbar-header {
        border-bottom: 0;
    }

    .border-bottom {
        border-bottom: 1px solid $border-color!important;
    }

    .btn-base {
        font-size: 1.4rem;
    }

    .vCenter {
        display: flex;
        align-items: center;
    }

    .form-control {
        &.focus, &:focus {
            box-shadow: none;
            outline: none;
            border-color: $border-color;
        }
        font-size: $font-size-default;
        @extend .rounded-0;
    }

    .btn, button {
        font-size: 1.3rem;

        &.focus, &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    select {
        -webkit-appearance: none;
           -moz-appearance: none;
            -ms-appearance: none;
             -o-appearance: none;
                appearance: none;

        &.focus, &:focus {
            box-shadow: none;
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }

    .main {
        padding-top: $h-nav-fixed-top-sticky + $h-mega-menu-sticky;

        @include media-breakpoint-down(md) {
            padding-top: $h-navbar-mobile;
        }

        &.home {
            padding-top: 0;
        }
    }

    // titres
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        a:hover {
            text-decoration: none;
        }
    }

    h1 {
        @include h1;
        @include title;
        @include margin(top);
    }

    h2 {
        @include h2;
        @include title;
        @include margin(top);
    }

    h3 {
        @include h3;
    }

    h4 {
        @include h4;
    }

    h1, h2, h3, h4, h5, h6, .is-h1, .is-h2, .is-h3, .is-h4, .is-h5, .is-h6 {
        line-height: 1.1;
    }

    p {
        line-height: 1.5;
    }

    .media-image h1 {
        margin-top: 0;

        @include media-breakpoint-down(md) {
            font-size: 3rem;
        }
    }

    // paragraph
    .paragraph {
        p, ul, ol {
            @include margin(bottom, $gap-sm);
            line-height: 1.5;
            font-size: $font-size-big2;
            font-weight: 300;

            &.lead {
                @include size(big3);
                @include font(bold);
                text-align: center;
            }
        }

        p:last-child {
            @include margin(none);
        }

        blockquote {
            @include text-border;
            @include size(big2);
        }

        b, strong {
            font-weight: bold;
        }

        u {
            border-width: 0 0 0.1rem 0;
        }

        pre {
            white-space: normal;
        }

        table {
            background-color: darken($white, 5%);

            td, th {
                padding: $gap;
                border-right-width: .2rem;
                border-left-width: .2rem;
                border-color: $white;
                border-style: solid;
            }

            th {
                @include font(bolder);
                @include blue;
                text-transform: uppercase;
                border-bottom-width: .2rem;
            }
        }
    }

    .paragraph,
    .lead {
        ul {
            margin-left: 3rem;
            list-style-type: disc;
        }

        ol {
            margin-left: 3rem;
            list-style-type: decimal;
        }

        a {
            @include green;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .page-link {
        &:hover {
            background: transparent;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

.lead {
    @include size(big3);
    @include font(bold);
    text-align: center;
}

.autoblock-vertical {
    .wrapper-2-items {
        .only-text:not(:last-child) {
            @extend .mb-5;
        }
    }
}

body {
    &.no-scroll {
        overflow: hidden;
    }
}

.pagination {
    li {
        &.active {
            color: $secondary-color;
        }
    }
}

.figure {
    &.push-image {
        height: auto;
        min-height: 100px;
    }
}

.list-result {
    .abstract a {
        &:hover {
            text-decoration: none;
        }
    }
}

.autoblock-vertical {
    .only-text:last-child {
        margin-bottom: 0 !important;
    }
}

// Cloud
.has-sub-elements .cloud-edit-content .cloud-left.is-in-content {
    top: 3rem !important;
    right: 1rem !important;
}

.small-col {
    .metadata-wrapper {
        font-size: 1.3rem;
    }
}

.cloud-ui-wrapper .cloud-tag {
    &, & span {
        text-transform: unset !important;
    }
}

.g-recaptcha {
    & > div {
        margin: 0;
    }
}

.cloud-text-placeholder:before {
    line-height: 1.5;
    font-size: 2rem;
}

body:not(.cloud-dragging) .cloud-rte-enabled {
    min-height: 30px !important;
}

.search-page-table-wrapper .table {
    th, td {
        font-size: 1.25rem !important;
    }
}

.cloud-dialog .popin-ok,
button.ui-button.ui-corner-all.ui-widget:not(.popin-cancel) {
    color: #fff !important;
}

.cloud-ui-wrapper .tag-list-container .tag-list .item-level-1 {
    h1 {
        font-size: 1.92rem !important;
    }

    .item-level-2 h2 {
        font-size: 1.6rem !important;
    }

    .item-level-2 .item-level-3 h3 {
        font-size: 1.4rem !important;
    }
}


.button {
    .arrowLink {
        padding-right: 7rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-width: 3px;

        &.btn-lg {
            padding-right: 10rem;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
            padding-left: 5rem;
        }

        &:before {
            right: 2rem;
            top: 50%;
            transform: translate3d(0,-50%,0);
        }
    }
}