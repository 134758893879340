////////////////// SOCIAL MEDIA LISTING //////////////////
.listSocialMedia-wrapper {
  @include margin(top, $gap-xl);
  @include margin(bottom, $gap-xl);
  @include grid(small);
}

@mixin listSocialMedia($type) {
  @include padding(left, calc(#{$gap} + #{$gap-xl}));
  position: relative;
  min-height: $gap-xl;

  @include icon(#{'card-' + $type}){
    position: absolute;
    top: 0;
    left: 0;
    font-size: $gap-xl;
    @if $type == facebook {
      color: $blue;
    } @else if $type == twitter {
      color: $green;
    }

    @include media-breakpoint-down(md){
      font-size: $gap-md;
    }
  }

  @include media-breakpoint-down(md){
    @include padding(left, 0);
  }
}

.listSocialMedia--facebook {
  @include listSocialMedia(facebook);
}

.listSocialMedia--twitter {
  @include listSocialMedia(twitter);
}

.listSocialMedia-title {
  @include title;
  @include size(big2);
  position: relative;

  @include icon(arrow){
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
    color: $text;
    @include size(bigger1);
  }

  @include media-breakpoint-down(md){
    @include padding(left, calc(#{$gap-md + $gap-sm}));
    @include margin(bottom, $gap-md)
  }
}
