.member-space-page {
    .green,
    .text-color-secondary,
    .text-secondary {
        color: $green2 !important;
    }

    .bg-secondary,
    .text-border-right-bolder:after,
    .icon-arrow-outline.green:after,
    .icon-arrow-outline.green:before {
        background-color: $green2 !important;
    }

    .btn-secondary {
        background-color: $green2 !important;
        border-color: $green2 !important;
    }

    .custom-radio .custom-control-input:checked~.custom-control-label:before {
        background-color: $green2 !important;
        border-color: $green2 !important;
    }
}
