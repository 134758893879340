////////////////// FOOTER //////////////////

#footer {
  background-color: darken($white, 2%);
  @include margin(top, auto); // needed to push item to the bottom of the page (the parent is Flex)

  [class^="footer-logo-"] {
    min-width: 180px;
  }
}

////////////////// FOOTER LOGO

.footer-logo {
  @include grid;
  @include flex(row);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $gap 0;

  > div {
    margin: 0 calc(-#{$gap-md} / 2);
    @include media-breakpoint-down(md) {
      // width: calc(100% + #{$gap-md});
      @include align(center);
    }
  }

  &-2 {
    width: 460px;
  }
}

////////////////// FOOTER BOTTOM

.footer-copyright,
.footer-social {
  // width: 21rem; // needed for centering the menu /!\
  max-width: 100%;
}

.footer-copyright {
  text-transform: uppercase;
  @include margin(right, auto);
  @include media-breakpoint-down(md) {
    @include margin(bottom, $gap-md);
    @include margin(right, 0);
  }
}

.footer-nav {
  .bb-content.navbar-expand {
    @include margin(left, 0 !important); // override .ml-md-auto
    justify-content: center; // override .navbar-expand

    .navbar-nav {
      margin: 0 calc(-#{$gap-md} / 2);
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: row; // override ..navbar-expand .navbar-nav
      float: none !important; //override .float-md-right
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .nav-item {
        margin: 0 calc(#{$gap-md} / 2);

        @include media-breakpoint-down(md) {
          @include margin(auto);
          &:not(:last-child) {
            @include margin(bottom);
          }
        }

        .nav-link {
          padding: 0; // override .navbar-expand .navbar-nav .nav-link

          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}

// cloud
.cloud-editable li.menu-add-parameter {
  display: block;
}

.footer-social {
  @include margin(left, auto);
  @include media-breakpoint-down(md) {
    @include margin(top, $gap-md);
    @include margin(left, 0);
  }

  .bb-content {
    text-align: right !important; // override .text-center
    @include media-breakpoint-down(md) {
      text-align: center !important;
    }

    .list-inline.social-list .list-inline-item {
      &:not(:last-child) {
        @include margin(right);
      }

      [class^="social-icon-"] {
        width: auto; // override of .social-list li a
        height: auto; // override of .social-list li a
        line-height: normal; // override of .social-list li a
        font-size: 2rem;
        border: 0; // override of .social-list li a
        color: $white;
        @include transition($ui-transition);

        &:hover {
          color: darken($white, 10%);
        }
      }
    }
  }
}

.footer-bottom {
  @include size(small);
  padding: $gap 0;
  color: $white;
  background-color: $blue;
}

.footer-bottom-wrapper {
  @include grid;
  @include flex(row);
  align-items: center;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    > * {
      @include align(center);
      width: 100%;
    }
  }
}

.footer-min-block {
  min-height: 22px;
  min-width: 22px;
}