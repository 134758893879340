////////////////// SLIDER HIGHLIGHT //////////////////
.sliderHighlight-wrapper {
  @include verticalCarousel;
  @include grid(small);
  @include margin(top, $gap-xl);
  @include margin(bottom, $gap);
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 0;
    width: 100%;
    max-width: unset;

    // Place dots on top (mobile)
    .owl-carousel {
      display: flex;
      flex-direction: column-reverse;
    }

    .owl-stage-outer {
      margin-top: 2rem;
    }
  }
}

.sliderHighlight-title {
  position: absolute;
  top: $gap-md;
  left: 0;
  @include bicolorTitle;
  z-index: 2;

  @include media-breakpoint-down(sm) {
    position: static;
    @include margin(bottom, $gap-sm);
  }
}

.sliderHighlight-item {
  @include sliderWrapper;
}

.sliderHighlight-image {
  @include dash-decoration;
  @include margin(left, 12rem);
  min-height: 30rem;

  @include media-breakpoint-down(sm) {
    @include margin(none);
    &:before {
      display: none;
    }
  }

  &.no-trait {
    &:before {
      display: none;
    }
  }
}

.sliderHighlight-content {
  padding: $gap $gap-md;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    padding: 2rem;
  }
}
