////////////////// LAYOUT //////////////////

.bb-site-wrapper  {
  display: flex;
  flex-direction: column;

  &.page-error {
    @include media-breakpoint-up(lg) {
      padding-top: $h-mega-menu;
      min-height: calc(100vh - 220px); // - footer height
    }
  }

  > * {
    width: 100%;
  }
}

.page-error-content {
  min-height: calc(100vh - 480px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// @include media-breakpoint-up(lg) {
//   min-height: calc(100vh - 310px);
// }

.sous-theme-actu,
.sous-theme-agenda,
.sous-theme-document {
  h4 {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}
