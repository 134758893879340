.btn-close {
    position: absolute;
    right: 0;
    z-index: 1;

    i {
        font-size: 4rem !important;
        color: $primary-color;
    }
}
