.cookie-wrapper {
    position: fixed;
    bottom: 0;
    z-index: $z-index-menu + 1;
    box-shadow: 0 -6px 13px rgba(#000, .1);
    @include transition(all 250ms ease-in-out);
    max-height: 50vh;
    overflow-y: auto;

    .cookie-content {
        @include grid(small);
    }
}