.regional-map-block {
    .region-list {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: $spacer * 2;

            li {
                padding: calc($spacer / 2) 0;

                .region-list-item {
                    @include transition($ui-transition);

                    &.active {
                        color: $green;
                    }
                }
            }
        }
    }

    .map-wrapper {
        padding-right: 20rem;

        ul {
            position: absolute;
            top: 0;
            left: 0;
            right: 10rem;
            height: 44rem;
            z-index: 1;

            li {
                position: absolute;
            }
        }

        .info-bubble {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            width: 32rem;
            left: 4rem;
            bottom: 1.6rem;
            z-index: 1;
            @include transition($ui-transition);

            .bg-primary:before {
                content: '';
                position: absolute;
                left: -1rem;
                bottom: .5rem;
                width: 3rem;
                border-top: .2rem solid #0acc82;
                transform: rotate(135deg);
                z-index: 1;
            }

            &.show {
                opacity: 1;
                visibility: visible;
            }

            .btn-close {
                position: absolute;
                padding: 1.6rem;
                top: 0;
            }

            a[href^="mailto:"] {
                text-decoration: underline;

                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
        }
    }

    .region-mark {
        cursor: pointer;
    }

    .map {
        width: 44rem;
    }

    .mt-3 {
        a.btn {
            padding: 0;

            &:hover {
                color: $green;
            }
        }
    }
}

#nord-pas-de-calais {
    top: 10%;
    left: 42%;
}

#normandie {
    top: 24%;
    left: 29%;
}

#picardie-champagne-ardenne {
    top: 20%;
    left: 48%;
}

#grand-est {
    top: 28%;
    left: 60%;
}

#ile-de-france {
    top: 25%;
    left: 41%;
}

#centre-val-de-loire {
    top: 38%;
    left: 36%;
}

#bourgogne-franche-comte {
    top: 41%;
    left: 50%;
}

#ouest-atlantique {
    top: 34%;
    left: 20%;
}

#nouvelle-aquitaine {
    top: 56%;
    left: 31%;
}

#auvergne-rhone-alpes {
    top: 58%;
    left: 53%;
}

#occitanie {
    top: 72%;
    left: 33%;
}

#mediterranee {
    top: 71%;
    left: 58%;
}

@include media-breakpoint-down(md) {
    .region-list {
        ul {
            border: none !important;
        }
    }
}
